import zIndex from "@mui/material/styles/zIndex";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "d3";

const initialState = {
  loader: false,
  performedLogin: false,
  checkedForAuthentication: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    changeLoader: (state, action) => {
      state.loader = action.payload.value;
    },
    alterLoginCheckState: (state, action) => {
      state.performedLogin = action.payload.value;
    },
    alterAuthenticationCheckState: (state, action) => {
      state.checkedForAuthentication = action.payload.value;
    },
    clearFlagsOnLogout: (state) => {
      state.performedLogin = false;
      state.checkedForAuthentication = false;
    },
  },
});

export const {
  changeLoader,
  alterLoginCheckState,
  alterAuthenticationCheckState,
  clearFlagsOnLogout,
} = loaderSlice.actions;

export default loaderSlice.reducer;
