import { getAuth } from "firebase/auth";
import { store } from "../src/app/store";
import { alterCheckedIsLoggedIn } from "./features/auth/authSlice";

function onAuthStateChange(callback) {
  const auth = getAuth();

  return auth.onAuthStateChanged((user) => {
    store.dispatch(alterCheckedIsLoggedIn(true));
    if (user) {
      callback(user.toJSON());
    } else {
      callback(null);
    }
  });
}

export default onAuthStateChange;
