import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../features/auth/authSlice";
import commentSlice from "../features/comment/commentSlice";
import loaderSlice from "../features/loader/loaderSlice";
import journeySlice from "../features/journey/journeySlice";
import subscriptionSlice from "../features/subscriptions/subscriptionSlice";

export const store = configureStore({
	reducer: {
		auth: authSlice,
		comment: commentSlice,
		loading: loaderSlice,
		subscriptions: subscriptionSlice,
		journey: journeySlice
	},
	devTools: false,
});
