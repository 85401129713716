import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "./Loading";

export const ProtectedRoute = ({ children }) => {
  const authUser = useSelector((state) => state.auth.user);
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  if (authUser && !selectedRole) {
    return <Loading />;
  }
  if (!authUser) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
