import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSubscriptionList = createAsyncThunk(
	"subscriptions/get",
	async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/subscriptions/`,
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			return error;
		}
	}
);

export const fetchCustomerSubscriptionList = createAsyncThunk(
	"subscriptions/get",
	async (customer) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/subscriptions/${customer.resellerId}/${customer.customerId}/${customer.customerName}`,
				{
					withCredentials: true,
					headers: {
						Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
					},
				}
			);
			return response.data;
		} catch (error) {
			return error;
		}
	}
);

const initialState = {
	subscriptionsList: { status: "idle", data: [], error: {} },
};

export const subscriptionSlice = createSlice({
	name: "subscriptions",
	initialState,
	reducers: {
		clearDataOnLogout: (state) => {
			state.subscriptionsList = { status: "idle", data: [], error: {} };
		},
	},
	extraReducers: {
		[fetchSubscriptionList.pending.type]: (state, action) => {
			state.subscriptionsList = {
				status: "loading",
				data: [],
				error: {},
			};
		},
		[fetchSubscriptionList.fulfilled.type]: (state, action) => {
			state.subscriptionsList = {
				status: "idle",
				data: action.payload,
				error: [],
			};
		},
		[fetchSubscriptionList.rejected.type]: (state, action) => {
			state.subscriptionsList = {
				status: "idle",
				data: [],
				error: action.payload,
			};
		},
		[fetchCustomerSubscriptionList.pending.type]: (state, action) => {
			state.subscriptionsList = {
				status: "loading",
				data: [],
				error: {},
			};
		},
		[fetchCustomerSubscriptionList.fulfilled.type]: (state, action) => {
			state.subscriptionsList = {
				status: "idle",
				data: action.payload,
				error: [],
			};
		},
		[fetchCustomerSubscriptionList.rejected.type]: (state, action) => {
			state.subscriptionsList = {
				status: "idle",
				data: [],
				error: action.payload,
			};
		},
	},
});

export const { clearDataOnLogout } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
