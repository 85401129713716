import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { changeLoader } from "./features/loader/loaderSlice";
import { lazy, Suspense, useEffect, useState } from "react";
import { ProtectedRoute } from "./components/Common/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/system";
import onAuthStateChange from "./firebase";
import {
  fetchUserRoles,
  login,
  logout,
  tempLogin,
} from "./features/auth/authSlice";
import { clearDataOnLogout } from "./features/subscriptions/subscriptionSlice";
import { getAuth, signOut } from "firebase/auth";
import { notify } from "./components/Common/Notifications";
import Loading from "./components/Common/Loading";
import { mainJourney, mainJourneyMobile } from "./assets/Data/steps.js";
import { changeJourneySubscription } from "./features/journey/journeySlice";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

//const SignInSide = lazy(() => import("./pages/Login"));
const SignInSide = lazy(() => import("./pages/Loginpage.js"));
const DashboardLayout = lazy(() => import("./components/DashboardLayout"));
const Subscriptions = lazy(() =>
  import("./components/Dashboard/Subscriptions")
);
const SubscriptionDetails = lazy(() =>
  import("./components/Dashboard/SubscriptionDetails")
);
const AdminRequest = lazy(() =>
  import("./components/Dashboard/Admin/AdminRequests")
);
const Requests = lazy(() => import("./components/Dashboard/Customer/Requests"));
const Profile = lazy(() => import("./components/Dashboard/Customer/Profile"));
const PointOfContact = lazy(() => import("./components/Dashboard/Customer/PointOfContact"));
const Insights = lazy(() => import("./components/Dashboard/Insights"));
const UserManagement = lazy(() =>
  import("./components/Dashboard/Admin/UserManagement")
);
const CustomerManagement = lazy(() =>
  import("./components/Dashboard/Admin/CustomerManagement")
);
const FeedbackTable = lazy(() =>
  import("./components/Dashboard/Admin/FeedbackTable")
);
const Layout = lazy(() => import("./components/Layout"));
const Landing = lazy(() => import("./pages/Landing"));

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  // if 401, console.log "hello from 401"
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const auth = getAuth();
      signOut(auth).then(() => {
        // Sign-out successful.
        notify("error", "Session Expired");
      });
    }
    return Promise.reject(error);
  }
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#fffc",
    },
    secondary: {
      main: "#0064FF",
    },
  },
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

function App() {
  const authState = useSelector((state) => state.auth);
  const [userRole, setUserRole] = useState("none");
  const [journey, setJourney] = useState({ run: true, stepIndex: 0 });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [checkedIsLoggedIn, setCheckedIsLoggedIn] = useState(false);
  //  const [loading, setLoading] = useState(!authState.auth?.user);
  const isAdmin = userRole === "admin";
  const isCustomer = userRole === "customer";
  const isClientOps = userRole === "clientops";
  const isUnassigned = userRole === "unassigned";

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loader);
  const subscription = useSelector(
    (state) => state.subscriptions.subscriptionsList
  );
  const checkedIsLoggedIn = useSelector(
    (state) => state.auth.checkedIsLoggedIn
  );
  const journeyState = useSelector((state) => state.journey.subscriptionFirst);

  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const {arrayMessage} = useSelector((state) => state.comment);

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  useEffect(() => {
    if (window.localStorage.length === 0) {
      window.localStorage.setItem("requestFirst", true);
      window.localStorage.setItem("subCardFirst", true);
      window.localStorage.setItem("subscriptionFirst", true);
    }
    // dispatch(changeJourneySubscription({ subscriptionFirst: window.localStorage.getItem("subscriptionFirst") }));

    const unsubscribe = onAuthStateChange((user) => {
      if (!authState.user && !authState.selectedRole) {
        if (!user) {
          dispatch(tempLogin(null));
          dispatch(logout());
          dispatch(clearDataOnLogout());
          return;
        }
        const accessToken = user?.stsTokenManager?.accessToken;
        dispatch(fetchUserRoles());
        // dispatch(login({ user, accessToken }));
        dispatch(tempLogin(user));

        // setCheckedIsLoggedIn(true);
      }
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      authState.user &&
      authState.roles &&
      authState.roles.length &&
      authState.selectedRole
    ) {
      if (authState.selectedRole === "disabled") {
        const auth = getAuth();
        signOut(auth).then(() => {
          dispatch(clearDataOnLogout());
          dispatch(logout());
        });
        return;
      }
      setUserRole(authState.selectedRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.roles, authState.selectedRole, authState.user]);

  useEffect(() => {
    if (
      subscription.status === "loading" ||
      (authState.user && userRole === "none")
    )
      dispatch(changeLoader({ value: true }));
    else dispatch(changeLoader({ value: false }));
  }, [authState.user, dispatch, subscription.status, userRole]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type, lifecycle } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour

      setJourney((prev) => ({
        ...prev,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      }));
      //   this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      //   this.setState({ run: false });
      setJourney((prev) => ({ ...prev, run: false }));
      dispatch(changeJourneySubscription({ subscriptionFirst: false }));

      window.localStorage.setItem("subscriptionFirst", false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/login" />,
    },
    {
      path: "login",
      // authState.user ? (
      element: authState.roles?.length ? (
        isAdmin || isClientOps ? (
          <Navigate to="/dashboard" />
        ) : (
          <Navigate to="/landing" />
        )
      ) : (
        <SignInSide />
      ),
      // ) : (
      //   <SignInSide />
      // ),
    },
    {
      path: "/landing",
      element: <Landing />,
    },
    {
      path: "/landing",
      element: <Landing />,
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={
                isAdmin || isClientOps
                  ? "requests"
                  : isCustomer
                  ? "subscriptions"
                  : isUnassigned
                  ? "unassigned"
                  : "disabled"
              }
            />
          ),
        },
        {
          path: "subscriptions",
          element: isCustomer ? (
            <Suspense fallback={<Loading />}>
              <Subscriptions />
            </Suspense>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "subscriptions/:id",
          element: isCustomer ? (
            <Suspense fallback={<Loading />}>
              <SubscriptionDetails />
            </Suspense>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "requests",
          element:
            isAdmin || isClientOps ? (
              <Suspense fallback={<Loading />}>
                <AdminRequest />
              </Suspense>
            ) : isCustomer ? (
              <Suspense fallback={<Loading />}>
                <Requests />
              </Suspense>
            ) : (
              <Navigate to="/" />
            ),
        },
        {
          path: "profile",
          element: isCustomer ? (
            <Suspense fallback={<Loading />}>
              <Profile />
            </Suspense>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "point-of-contact",
          element: isCustomer ? (
            <Suspense fallback={<Loading />}>
              <PointOfContact />
            </Suspense>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "insights",
          element: isAdmin ? (
            <Suspense fallback={<Loading />}>
              <Insights />
            </Suspense>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "userManagement",
          element: isAdmin ? (
            <Suspense fallback={<Loading />}>
              <UserManagement />
            </Suspense>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "customer-user-management",
          element: isAdmin ? (
            <Suspense fallback={<Loading />}>
              <CustomerManagement />
            </Suspense>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "feedback",
          element:
            isAdmin || isClientOps ? (
              <Suspense fallback={<Loading />}>
                <FeedbackTable />
              </Suspense>
            ) : (
              <Navigate to="/" />
            ),
        },
        {
          path: "unassigned",
          element: isUnassigned ? (
            <Box>
              <h1>
                No roles have been assigned to you. Please contact your
                administrator.
              </h1>
            </Box>
          ) : (
            <Navigate to="/" />
          ),
        },
        {
          path: "disabled",
          element: authState.selectedRole === "disabled" && (
            <Box>
              <h1>
                You are unauthorized to access this page and your account has
                been disabled. Please contact your administrator.
              </h1>
            </Box>
          ),
        },
      ],
    },
  ]);

  if (!checkedIsLoggedIn || (authState.user && userRole === "none")) {
    return <Loading />;
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {loading ? (
          <Loading />
        ) : (
          (journeyState &&
            isCustomer &&
            window.localStorage.getItem("subscriptionFirst")) === "true" && (
            <Joyride
              disableBeacon={true}
              disableScrolling={false}
              disableScrollParentFix={true}
              continuous
              callback={handleJoyrideCallback}
              hideCloseButton
              scrollToFirstStep
              showProgress
              showSkipButton
              stepIndex={journey.stepIndex}
              steps={isMobile ? mainJourneyMobile : mainJourney}
              run={journey.run}
              styles={{
                options: {
                  primaryColor: "#0064ff",
                },
              }}
            />
          )
        )}
        <RouterProvider router={router} />
      </ThemeProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
