import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuth, signOut } from "firebase/auth";
import { changeLoader, hideLoader, showLoader } from "../loader/loaderSlice";

const initialState = {
  user: null,
  roles: null,
  selectedRole: null,
  accessToken: null,
  tempRoles: { status: "idle", data: [], error: {} },
  tempUser: null,
  checkedIsLoggedIn: false,
};
export const fetchUserRoles = createAsyncThunk(
  "roles/get",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(changeLoader({ value: true }));
      const auth = getAuth();
      const user = auth.currentUser;
      const accessToken = user?.stsTokenManager?.accessToken;
      const response = await user.getIdToken().then(async (token) => {
        console.log("checking...");
        return await axios.post(
          `${process.env.REACT_APP_API_URL}/auth`,
          {
            token,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
            },
          }
        );
      });

      return { roles: response.data?.roles, user: user.toJSON(), accessToken };
    } catch (error) {
      return error;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: {
      reducer: (state, action) => {
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
      },
      prepare: (payload) => {
        return {
          payload: {
            user: payload.user,
            accessToken: payload.accessToken,
          },
        };
      },
    },
    setRoles: (state, action) => {
      state.roles = action.payload.roles;
      state.selectedRole = action.payload.roles[0];
    },
    logout: (state) => {
      state.user = null;
      state.roles = null;
      state.selectedRole = null;
      state.accessToken = null;
      state.tempRoles = { status: "idle", data: [], error: {} };
    },
    tempLogin: (state, action) => {
      state.tempUser = action.payload;
    },
    alterCheckedIsLoggedIn: (state, action) => {
      state.checkedIsLoggedIn = action.payload;
    },
  },
  extraReducers: {
    [fetchUserRoles.pending.type]: (state, action) => {
      state.tempRoles = {
        status: "loading",
        data: [],
        error: {},
      };
    },
    [fetchUserRoles.fulfilled.type]: (state, action) => {
      state.tempRoles = {
        status: "idle",
        data: action.payload.roles,
        error: {},
      };
      state.roles = action.payload.roles;
      state.selectedRole = action.payload.roles[0];
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    [fetchUserRoles.rejected.type]: (state, action) => {
      state.tempRoles = {
        status: "idle",
        data: [],
        error: action.payload,
      };
    },
  },
});

export const { login, logout, setRoles, tempLogin, alterCheckedIsLoggedIn } =
  authSlice.actions;

export default authSlice.reducer;
