export const mainJourney = [
	{
		title: "Profile",
		target: "#profile",
		content: "Click to see your profile page",
		disableBeacon: true,
		placement: "bottom",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
	{
		title: "Feedback",
		target: "#feedback",
		content: "You can submit your feedback here",
		disableBeacon: true,
		placement: "right-end",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
	{
		title: "Subscription List",
		target: "#list-subscriptions",
		content: "Your subscriptions will be listed here",
		placement: "right-end",
		styles: {
			options: {
				width: 300,
				display: "none",
			},
		},
	},

	{
		title: "Sidebar Button",
		target: "#dashboard-button",
		content: "Click to toggle the sidebar",
		placement: "bottom",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
	{
		title: "Subscriptions",
		target: "#subscriptions",
		content: "Click here to see your subscriptions",
		placement: "right",
		styles: {
			options: {
				width: 300,
				zIndex: 2500,
				// Zindex: 1000
			},
		},
	},
	{
		title: "Requests",
		target: "#requests",
		content: "Click here to see your requests",
		placement: "right",
		styles: {
			options: {
				width: 300,
				// zIndex: 12000
				// Zindex: 1000
			},
		},
	},
	{
		title: "Search",
		target: "#search",
		content: "Type in the search box to search for a subscription",
		placement: "right-end",
		styles: {
			options: {
				width: 300,
			},
		},
	},
	{
		title: "Logout",
		target: "#logout",
		content: "Click here to log out",
		placement: "right-end",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
];

export const mainJourneyMobile = [
	{
		title: "Profile",
		target: "#profile",
		content: "Click to see your profile page",
		disableBeacon: true,
		placement: "bottom",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
	{
		title: "Feedback",
		target: "#feedback",
		content: "You can submit your feedback here",
		disableBeacon: true,
		placement: "right-end",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
	{
		title: "Subscription List",
		target: "#list-subscriptions",
		content: "Your subscriptions will be listed here",
		placement: "right-end",
		styles: {
			options: {
				width: 300,
				display: "none",
			},
		},
	},

	{
		title: "Sidebar Button",
		target: "#dashboard-button",
		content: "Click to toggle the sidebar",
		placement: "bottom",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
	{
		title: "Search",
		target: "#search",
		content: "Type in the search box to search for a subscription",
		placement: "right-end",
		styles: {
			options: {
				width: 300,
			},
		},
	},
	{
		title: "Logout",
		target: "#logout",
		content: "Click here to log out",
		placement: "right-end",
		styles: {
			options: {
				width: 300,
				zIndex: 1300,
			},
		},
	},
];

export const requestJourney = [
	{
		title: "Request",
		target: "#requestTable",
		content: "This table shows a list of your requests",
		placement: "start",
		disableBeacon: true,
		styles: {
			options: {
				width: 300,
			},
		},
	},
	{
		title: "Filter",
		target: "#filter",

		content: "Click to toggle filters",
		placement: "bottom",
		styles: {
			options: {
				width: 300,
			},
		},
	},
	{
		title: "Status",
		target: ".InovuaReactDataGrid__box--ellipsis:nth-of-type(2)",
		content: "Click on request status check details or edit/cancel the request",
		placement: "left",
		styles: {
			options: {
				width: 300,
				zIndex: 12000,
			},
		},
	},
];

export const subscriptionDetailJourney = [
	{
		title: "Subscription Table",
		target: "#subscription-detail-table",
		content:
			"This table shows the list of SKUs in the subscription you selected",
		placement: "top",
		disableBeacon: true,
		styles: {
			options: {
				width: 300,
			},
		},
	},
	{
		title: "Add License",
		target:
			".InovuaReactDataGrid__column-header__resize-wrapper:nth-of-type(3)",
		content:
			"Current license utilization is shown here. Click to request to add more licenses",
		placement: "top",
		styles: {
			options: {
				width: 300,
			},
		},
	},
	{
		title: "Upgrade License",
		target:
			".InovuaReactDataGrid__column-header__resize-wrapper:nth-of-type(5)",
		content: "Click here to request an upgrade for the SKU",
		placement: "top",
		styles: {
			options: {
				width: 300,
			},
		},
	},
];
