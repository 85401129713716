import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    requestFirst: true,
    subCardFirst: true,
    subscriptionFirst: true,
};

export const journeySlice = createSlice({
    name: "journey",
    initialState,
    reducers: {
        changeJourneyRequest: (state, action) => {
            state.requestFirst = action.payload.requestFirst;
        },
        changeJourneySubCard: (state, action) => {
            state.subCardFirst = action.payload.subCardFirst;
        },
        changeJourneySubscription: (state, action) => {
            state.subscriptionFirst = action.payload.subscriptionFirst;
        },
    },
});

export const {
    changeJourneyRequest,
    changeJourneySubCard,
    changeJourneySubscription,
} = journeySlice.actions;

export default journeySlice.reducer;
