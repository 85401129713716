import { createSlice } from "@reduxjs/toolkit";
import { getAuth, signOut } from "firebase/auth";

const initialState = {
    arrayMessage: [ {
        id: "1",
        date: "17-Jun-2022",
        email: "efg@searce.com",
        message: "Lorem ipsum dolor sit amet",
      },
      {
        id: "2",
        date: "20-Aug-2022",
        email: "abc@searce.com",
        message: "Lorem ipsum dolor sit amet",
      },
      {
        id: "3",
        date: "11-Sept-2022",
        email: "abc@searce.com",
        message:
          "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
      },
      {
        id: "4",
        date: "15-Sept-2022",
        email: "efg@searce.com",
        message: "Lorem ipsum dolor sit amet",
      },]
};

export const commentSlice = createSlice({
    name: "comment",
    initialState,
    reducers: {
        addMessage: (state, action) => {
            const date = new Date();
            if (action.payload.comment !== undefined)
            
                state.arrayMessage=[...state.arrayMessage,{
                    id: "123",
                    date:
                        date.getDay() + "-" + date.getMonth() + "-" + date.getFullYear(),
                    email: "efg@searce.com",
                    message: action.payload.comment,
                }]
               
        },
		
    },
});

export const { addMessage} = commentSlice.actions;

export default commentSlice.reducer;
